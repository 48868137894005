/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { getApi } from '../api';
import { fetchSearchFail, replaceSearchEntities, fetchSearch } from './search';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createNotification } from '../common/notifications/createNotification';
import * as TextMapping from '../utils/textMapping';

import { v4 as uuidv4 } from 'uuid';
import { showLocalNotification } from './localNotifications';

import { convertUnit } from '../utils/units';

export function clearApps() {
  return {
    type: 'CLEAR_MATERIALS',
  };
}

export function gettingAppsFail() {
  return {
    type: 'FETCH_FAILED_ROOTS',
  };
}

export function gettingApps() {
  return {
    type: 'GETTING_MATERIALS',
  };
}

export function downloadingMaterialCard(dest) {
  return {
    type: 'DOWNLOADING_MATERIAL_CARD',
    message: 'Downloading started...',
    status: 'progress',
    url: null,
    mode: dest,
  };
}

export function downloadingMaterialCardSuccess(data) {
  return {
    type: 'DOWNLOADING_MATERIAL_CARD_SUCCESS',
    message: data.message,
    url: data.url ? data.url : null,
    status: true,
    mode: data.mode,
  };
}

export function caeDownloadPreview(data) {
  return {
    type: 'CAE_DOWNLOAD_PREVIEW',
    data: data,
  };
}

export function resetCaeDownloadPreview() {
  return {
    type: 'CAE_DOWNLOAD_PREVIEW',
    data: null,
  };
}

export function clearCaeDownloadStatus() {
  return {
    type: 'CLEAR_CAE_DOWNLOAD_STATUS',
  };
}

export function downloadingMaterialCardFail(message) {
  return {
    type: 'DOWNLOADING_MATERIAL_CARD_FAILED',
    message: message,
    url: '',
    status: false,
    mode: null,
  };
}

export function favoriteActionFail() {
  return {
    type: 'FAVORITE_ACTION_FAILED',
  };
}

export function exportingMaterials() {
  return {
    type: 'EXPORTING_MATERIALS',
  };
}

export function exportingMaterialsFail() {
  return {
    type: 'EXPORTING_MATERIALS_FAILED',
  };
}

export function qtExportingMaterials() {
  return {
    type: 'QT_EXPORTING_MATERIALS',
  };
}

export function qtExportingMaterialsFail() {
  return {
    type: 'QT_EXPORTING_MATERIALS_FAILED',
  };
}

export function mapTagChildren(tag, featureCounts) {
  tag.count = featureCounts[tag.vkey];

  if (tag.children) {
    tag.children.map((child) => {
      return mapTagChildren(child, featureCounts);
    });
  }
}

export function replaceAppsClassifications(classifications, featureCounts) {
  let tags = [];

  classifications[0].children.forEach((tag) => {
    tags.push({
      id: tag.vkey,
      name: tag.name,
      displayName: tag.name,
      children: tag.children,
      pos: tag.pos,
      vkey: tag.vkey,
      shortname: tag.name,
      useMask: tag.use_mask,
      config: tag.node && tag.node.config && tag.node.config.length ? JSON.parse(tag.node.config) : null,
    });
  });

  tags.map((tag) => {
    return mapTagChildren(tag, featureCounts);
  });

  return {
    type: 'REPLACE_MATERIALS_CLASSIFICATIONS',
    classifications,
    tags,
  };
}

export function replaceApps(rootItems) {
  const items = rootItems.data.map((item) => {
    const id = item.id;
    return { ...item.system, ...item.attributes, id };
  });
  // let items = rootItems.data.filter(item=>{
  //     return(!item.attributes.computeInfo || item.attributes.name.toLowerCase() === 'optistruct')
  // });

  return {
    type: 'REPLACE_MATERIALS',
    counts: [],
    items: items,
  };
}

export function replaceProviders(rootItems) {
  const providers = rootItems.map((item) => {
    return { key: item.id, text: item.attributes.name };
  });
  // let items = rootItems.data.filter(item=>{
  //     return(!item.attributes.computeInfo || item.attributes.name.toLowerCase() === 'optistruct')
  // });
  return {
    type: 'REPLACE_PROVIDERS',
    counts: [],
    providers: providers,
  };
}

export function replaceUnitSystems(rootItems) {
  const getSelectItem = (unitSystem) => {
    if ('Mg' === unitSystem.attributes.mass && 'mm' === unitSystem.attributes.length && 's' === unitSystem.attributes.time) {
      return {
        key: unitSystem.id,
        text: 'N mm s Mg',
        isSelected: true,
      };
    }

    if ('kg' === unitSystem.attributes.mass && 'mm' === unitSystem.attributes.length && 'ms' === unitSystem.attributes.time) {
      return {
        key: unitSystem.id,
        text: 'kN mm ms kg',
      };
    }

    if ('g' === unitSystem.attributes.mass && 'mm' === unitSystem.attributes.length && 'ms' === unitSystem.attributes.time) {
      return {
        key: unitSystem.id,
        text: 'N mm ms g',
      };
    }

    if ('kg' === unitSystem.attributes.mass && 'm' === unitSystem.attributes.length && 's' === unitSystem.attributes.time) {
      return {
        key: unitSystem.id,
        text: 'N m s kg',
      };
    }

    return {
      key: unitSystem.id,
      text: unitSystem.attributes.name,
    };
  };

  const unitSystems = rootItems.map((item) => {
    return getSelectItem(item);
  });
  // let items = rootItems.data.filter(item=>{
  //     return(!item.attributes.computeInfo || item.attributes.name.toLowerCase() === 'optistruct')
  // });
  return {
    type: 'REPLACE_UNIT_SYSTEMS',
    counts: [],
    unitSystems: unitSystems,
  };
}

export function replaceSolvers(rootItems) {
  return {
    type: 'REPLACE_SOLVERS',
    counts: [],
    solvers: rootItems,
  };
}

export function replaceTexts(rootItems) {
  return {
    type: 'REPLACE_TEXTS',
    counts: [],
    texts: rootItems,
  };
}

export function replaceCAEPlots(rootItems) {
  return {
    type: 'REPLACE_CAE_PLOTS',
    plots: rootItems,
  };
}

export function replaceCAESelectedDiagram(rootItems) {
  return {
    type: 'REPLACE_CAE_SELECTED_DIAGRAM',
    plots: rootItems,
  };
}

export function replaceUnits(rootItems) {
  return {
    type: 'REPLACE_UNITS',
    counts: [],
    units: rootItems,
  };
}

export function replaceSIUnitSystem(rootItems) {
  return {
    type: 'REPLACE_SI_UNIT_SYSTEM',
    counts: [],
    siUnitSystem: rootItems,
  };
}

export function replaceApplicationMenu(menu) {
  return {
    type: 'REPLACE_APPLICATION_MENU',
    counts: [],
    supportedApplicationMenus: menu,
  };
}

export function downloadMaterialCardV1(payload) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();
    //dispatch(downloadingMaterialCard({message: "Starting downloading..."}));
    api.materials
      .downloadMaterialCardV1(payload)
      .then((response) => {
        if (response.status === 200 && (typeof response.data.success === 'undefined' || response.data.success)) {
          let data = {
            mode: payload.dest,
            url: null,
            status: true,
            message: response.data.message
              ? response.data.message
              : TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD_SUCCESS, roots.texts),
          };
          if (payload.dest === 'drive') {
            data.url = response.data.url;
            data.status = response.data.success;
          }
          dispatch(downloadingMaterialCardSuccess(data));
        } else {
          dispatch(
            downloadingMaterialCardFail(
              response.data.message ? response.data.message : TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD_FAILED, roots.texts)
            )
          );
        }
      })
      .catch((error) => {
        dispatch(downloadingMaterialCardFail(error.message));
        console.error(error);
      });
  };
}

export function downloadMaterialCard(
  id,
  model,
  solver,
  unit,
  startNumber,
  moistureState,
  qs,
  reductionFactor,
  temperature,
  temperatureUnitSystem,
  poissonsRatio,
  engTrueStressStrain,
  stressLimit,
  includeGeneric,
  encrypted,
  appContent,
  webSocket = null,
  diagram,
  dest
) {
  const api = getApi();
  return (dispatch, getState) => {
    if (dest === 'preview') {
      dispatch(caeDownloadPreview(null));
    }
    const { roots } = getState();

    if (window.opener && roots.pluginSource) {
      window.opener.focus();
    }

    if (dest !== 'preview') {
      dispatch(downloadingMaterialCard(dest));
    }
    api.materials
      .downloadMaterialCard(
        id,
        model,
        solver,
        unit,
        startNumber,
        moistureState,
        qs,
        reductionFactor,
        temperature,
        temperatureUnitSystem,
        poissonsRatio,
        engTrueStressStrain,
        stressLimit,
        includeGeneric,
        encrypted,
        appContent,
        webSocket,
        diagram,
        dest
      )
      .then((response) => {
        if (response.status === 200 && (typeof response.data.success === 'undefined' || response.data.success)) {
          if (dest === 'preview') {
            if (response.data instanceof Blob) {
              const reader = new FileReader();
              reader.addEventListener('loadend', (e) => {
                try {
                  const result = JSON.parse(e?.target?.result);

                  if (result && result.data) {
                    dispatch(caeDownloadPreview(result.data));
                  }
                } catch (e) {
                  console.error('Encountered error when parsing JSON', e);
                }
              });
              if (response && response.data) {
                reader.readAsText(response.data);
              }
            } else {
              let responseObject = JSON.parse(response.data);
              dispatch(caeDownloadPreview(responseObject.data));
            }
            //dispatch(caeDownloadPreview(response.data.text()));
          } else {
            if (window.opener) {
              const { roots } = getState();
              if (response && response.data) {
                response.data
                  .text()
                  .then((text) => {
                    if (roots.pluginSource) {
                      window.opener.postMessage({ type: 'caecard', value: text }, roots.pluginSource);
                      window.opener.focus();
                    }
                  })
                  .catch((e) => console.log(e));
              }
            }
            let data = {
              mode: dest,
              url: null,
              status: true,
              message: response.data.message
                ? response.data.message
                : TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD_SUCCESS, roots.texts),
            };
            if (dest === 'drive') {
              data.url = response.data.url;
              data.status = response.data.success;
            }

            dispatch(downloadingMaterialCardSuccess(data));
          }
        } else {
          dispatch(
            downloadingMaterialCardFail(
              response.data.message ? response.data.message : TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD_FAILED, roots.texts)
            )
          );
        }
      })
      .catch((error) => {
        dispatch(downloadingMaterialCardFail(error.message));
        console.error(error);
      });
  };
}

export function fetchPlots(temperature, reductionFactor, stressLimit, unit, language) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .fetchPlots(temperature, reductionFactor, stressLimit, unit, language, roots.appContent)
      .then((roots) => {
        dispatch(replaceCAEPlots(roots));
      })
      .catch((error) => {
        dispatch(downloadingMaterialCardFail());
        console.error(error);
      });
  };
}

export function getDiagram(id, unit, language) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getDiagram(id, unit, language, roots.appContent)
      .then((roots) => {
        dispatch(replaceCAESelectedDiagram(roots));
      })
      .catch((error) => {
        dispatch(downloadingMaterialCardFail());
        console.error(error);
      });
  };
}

export function favoriteAction(url, payload, favoriteList) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials.favoriteAction(url, payload, favoriteList, roots.appContent, roots.language, roots.unitSystem).catch((error) => {
      dispatch(favoriteActionFail());
      console.error(error);
    });
  };
}

export function downloadPDF(id, name, appContent, section = 'pdf', data = null, comment = null) {
  const api = getApi();

  return (dispatch, getState) => {
    dispatch({
      type: 'PDF_DOWNLOAD_WAITING',
      isWaiting: true,
      id: id,
    });

    const { loggedInUser, roots } = getState();
    const userId = get(loggedInUser, 'id');

    function handleError(message) {
      createNotification({
        type: 'EXPORT_FAILED',
        userId,
        variables: { body: message },
      });

      dispatch({
        type: 'PDF_DOWNLOAD_WAITING',
        isWaiting: false,
        id: id,
      });
    }
    let commentText = '';
    if (comment && comment.props && comment.props.dangerouslySetInnerHTML && comment.props.dangerouslySetInnerHTML.__html) {
      commentText = comment.props.dangerouslySetInnerHTML.__html;
    }

    api.materials
      .downloadPDF(id, name, appContent, dispatch, roots.language, roots.unitSystem, section, data, commentText, handleError)
      .catch((error) => {
        dispatch(downloadingMaterialCardFail());
        console.error(error);
      });
  };
}

export function downloadImpactAssessmentPDF(compounds, machines, country, total, co2PlotData, gwpPlotData, co2svg, gwpsvg) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .downloadImpactAssessmentPDF(
        roots.activeId,
        roots.appContent,
        roots.language,
        roots.unitSystem,
        compounds,
        machines,
        country,
        total,
        co2PlotData,
        gwpPlotData,
        co2svg,
        gwpsvg
      )
      .catch((error) => {
        dispatch(downloadingMaterialCardFail());
        console.error(error);
      });
  };
}

export function downloadXLS(id) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials.downloadXLS(id, roots.appContent, roots.language, roots.unitSystem).catch((error) => {
      dispatch(downloadingMaterialCardFail());
      console.error(error);
    });
  };
}

export function exportMaterials(materialIds, applicationType, applicationName) {
  const api = getApi();
  return (dispatch) => {
    dispatch(exportingMaterials());
    api.materials.exportMaterials(materialIds, applicationType, applicationName).catch((error) => {
      dispatch(exportingMaterialsFail());
      console.error(error);
    });
  };
}

export function buildTagFilters(qs, classifications) {
  let filteredTags = qs && qs.tags ? decodeURIComponent(qs.tags).split(',') : [];
  let tagsArray = [];

  classifications.forEach((classification) => {
    let currentTag = { Id: classification.id, filter: false };
    if (filteredTags.includes(classification.id)) {
      currentTag.filter = true;
    }
    tagsArray.push(currentTag);
  });

  return { tagFilters: tagsArray, counts: true };
}

export function updateLoadIndex(isFirstLoad = false) {
  return {
    type: 'UPDATE_LOAD_INDEX',
    firstLoad: isFirstLoad,
  };
}

export function updateOffset(offsetCount = 0) {
  return {
    type: 'UPDATE_OFFSET',
    offset: offsetCount,
  };
}

export function cleanEntityItems() {
  return {
    type: 'CLEAN_ENTITY_ITEMS',
  };
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export function getAdvSearchRanges(ranges, searchCatalog) {
  let advSearchRanges = JSON.parse(JSON.stringify(ranges));

  const mapChildren = (children) => {
    for (let child of children) {
      if (child.type === 'Spt') {
        if (child.children && child.children.length > 0) {
          let minArray = [];
          let maxArray = [];
          let minSpecialValue = null;
          let maxSpecialValue = null;

          if (ranges && ranges.Spt && ranges.Spt[child.vkey] && ranges.Spt[child.vkey].length > 1) {
            if (isString(ranges.Spt[child.vkey][0])) {
              minSpecialValue = ranges.Spt[child.vkey][0];
            } else {
              minArray.push(ranges.Spt[child.vkey][0]);
            }

            if (isString(ranges.Spt[child.vkey][1])) {
              maxSpecialValue = ranges.Spt[child.vkey][1];
            } else {
              maxArray.push(ranges.Spt[child.vkey][1]);
            }
          }
          for (let grandchild of child.children) {
            if (ranges && ranges.Spt && ranges.Spt[grandchild.vkey] && ranges.Spt[grandchild.vkey].length > 1) {
              if (isString(ranges.Spt[grandchild.vkey][0])) {
                minSpecialValue = ranges.Spt[grandchild.vkey][0];
              } else {
                minArray.push(convertUnit(ranges.Spt[grandchild.vkey][0], grandchild.unit_name, child.unit_name));
              }

              if (isString(ranges.Spt[grandchild.vkey][1])) {
                maxSpecialValue = ranges.Spt[grandchild.vkey][1];
              } else {
                maxArray.push(convertUnit(ranges.Spt[grandchild.vkey][1], grandchild.unit_name, child.unit_name));
              }
            }
          }

          if (!advSearchRanges.Spt[child.vkey]) advSearchRanges.Spt[child.vkey] = [];

          if (minSpecialValue) {
            advSearchRanges.Spt[child.vkey][0] = minSpecialValue;
          } else if (minArray.length) {
            advSearchRanges.Spt[child.vkey][0] = Math.min(...minArray);
          }

          if (maxSpecialValue) {
            advSearchRanges.Spt[child.vkey][1] = maxSpecialValue;
          } else if (maxArray.length) {
            advSearchRanges.Spt[child.vkey][1] = Math.max(...maxArray);
          }

          if (!advSearchRanges.Spt[child.vkey].length) delete advSearchRanges.Spt[child.vkey];
        }
      } else if (child.children && child.children.length > 0) {
        mapChildren(child.children);
      }
    }
  };

  if (searchCatalog && searchCatalog.length > 0) {
    mapChildren(searchCatalog[0].children);
  }

  return advSearchRanges;
}

let currentController = null;

export function getMaterials(qs, newSortColumn = '', newSortDirection = '', view = 'tiles', reload = false) {
  const api = getApi();

  return (dispatch, getState) => {
    dispatch(gettingApps());

    // Abort previous request if it exists
    if (currentController) {
      currentController.abort();
    }

    const { roots } = getState();

    // Create a new AbortController
    currentController = new AbortController();
    const { signal } = currentController;

    if (reload === true) {
      dispatch(cleanEntityItems());
      dispatch(updateOffset(0));
      dispatch(updateLoadIndex(true));
    }

    if (roots.filterData && Object.keys(roots.filterData).length > 0) {
      dispatch(fetchSearch(roots.filterData, qs, newSortColumn, newSortDirection, reload, signal));
    } else {
      if (roots.offset === 0 || reload) {
        dispatch({
          type: 'SET_RANGES_LOADING',
        });
        api.materials
          .getRanges(
            qs,
            roots.searchCatalog,
            roots.unitSystem,
            roots.appContent,
            roots.filterData,
            roots.previousFilterData,
            roots.ranges,
            roots.storedTags,
            roots.language,
            signal
          )
          .then((ranges) => {
            let advSearchRanges = getAdvSearchRanges(ranges, roots.searchCatalog);
            if (ranges) {
              dispatch({
                type: 'REPLACE_RANGES',
                ranges: ranges,
                advSearchRanges: advSearchRanges,
              });
            }
          })
          .catch((error) => {
            if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
              dispatch(fetchSearchFail());
              console.error(error);
            }
          });

        api.materials
          .getFeatureCount(qs, roots.unitSystem, null, roots.appContent, roots.language, signal)
          .then((featureCounts) => {
            api.materials
              .getClassifications(qs, roots.appContent, roots.language, roots.unitSystem)
              .then((roots) => {
                dispatch(replaceAppsClassifications(roots, featureCounts));
              })
              .catch((error) => {
                if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
                  dispatch(gettingAppsFail());
                  console.error(error);
                }
              });
          })
          .catch((error) => {
            if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
              dispatch(gettingAppsFail());
              console.error(error);
            }
          });
      }

      api.materials
        .getMaterials({
          qs,
          tableColumns: roots.tableColumns,
          classifications: roots.classifications,
          pageSize: roots.pageSize,
          offset: reload ? 0 : roots.offset,
          sort: newSortColumn,
          sortdir: newSortDirection,
          appContent: roots.appContent,
          view: view,
          language: roots.language,
          unitSystem: roots.unitSystem,
          preferences: roots.preferences,
          signal,
        })
        .then((roots) => {
          let tagCounts = [];

          if (roots.counts && roots.counts.tagCounts && roots.counts.tagCounts.individualTagCounts) {
            for (const tagKey in roots.counts.tagCounts.individualTagCounts) {
              tagCounts.push(roots.counts.tagCounts.individualTagCounts[tagKey]);
            }
          }

          let entities = roots.data;

          dispatch(
            replaceSearchEntities(entities, null, tagCounts, roots.total, roots.storedTags, roots.track, roots.offset, roots.success)
          );
        })
        .catch((error) => {
          if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
            dispatch(gettingAppsFail());
            throw error;
          }
        });
    }
  };
}

export function reload() {
  return {
    type: 'RELOAD',
  };
}
export function getProviders() {
  const api = getApi();
  return (dispatch) => {
    //dispatch(gettingApps());
    api.materials
      .getProviders()
      .then((roots) => {
        dispatch(
          replaceProviders(
            roots.filter((item) => {
              return !item.attributes.isSubGroup;
            })
          )
        );
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getUnitSystems() {
  const api = getApi();
  return (dispatch) => {
    //dispatch(gettingApps());
    api.materials
      .getUnitSystems()
      .then((roots) => {
        dispatch(replaceUnitSystems(roots));
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getTexts() {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    //dispatch(gettingApps());
    api.materials
      .getTexts(roots.language, roots.appContent)
      .then((roots) => {
        dispatch(replaceTexts(roots));
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getSIUnitSystem() {
  const api = getApi();
  return (dispatch) => {
    //dispatch(gettingApps());
    api.materials
      .getSIUnitSystem()
      .then((roots) => {
        dispatch(replaceSIUnitSystem(roots));
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getSolvers() {
  const api = getApi();
  return (dispatch) => {
    //dispatch(gettingApps());
    api.materials
      .getSolvers()
      .then((roots) => {
        dispatch(replaceSolvers(roots));
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getSolversForMaterials(ids) {
  const api = getApi();
  return (dispatch) => {
    api.materials
      .getSolversForMaterials(ids)
      .then((roots) => {
        dispatch(replaceSolvers(roots));
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getApplicationsMenuForMaterials(selectedMaterials) {
  const api = getApi();
  return (dispatch) => {
    api.materials
      .getApplicationsForMaterials(selectedMaterials[0].id)
      .then((roots) => {
        let applicationContextMenu = {
          items: [],
        };
        Object.keys(roots).forEach((applicationKey) => {
          const application = roots[applicationKey];
          let menu = {
            key: application[0],
            text: applicationKey,
          };
          applicationContextMenu.items.push(menu);
        });
        dispatch(replaceApplicationMenu(applicationContextMenu));
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getMaterial(id) {
  const api = getApi();
  if (!id) {
    return {
      type: 'SET_ACTIVE_APP',
      activeApp: '',
    };
  }
  return (dispatch) => {
    dispatch(gettingApps());
    api.materials
      .getMaterial(id)
      .then((activeApp) => {
        dispatch({
          type: 'SET_ACTIVE_APP',
          activeApp,
        });
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getAppContent(name, language = null) {
  const api = getApi();

  return (dispatch) => {
    api.materials
      .getAppContent(name)
      .then((appContent) => {
        if (isEmpty(appContent)) {
          throw new Error('Unauthorized');
        }
        dispatch({
          type: 'SET_APP_CONTENT',
          appContent,
          languageOverride: language,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'GET_APP_CONTENT_FAIL',
          error: error,
        });
      });
  };
}

export function getMaterialDetails(id, qs, appContent) {
  const api = getApi();
  return (dispatch) => {
    api.materials
      .getMaterialDetails(id, qs, appContent)
      .then((roots) => {
        dispatch({
          type: 'SET_MATERIAL_DETAILS',
          roots,
        });
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

function addUUIDs(child) {
  child.data.uuid = uuidv4();

  if (child.data.content) {
    for (let element of child.data.content) {
      if (typeof element === 'object' && element != null && !Array.isArray(element) && element.data) {
        addUUIDs(element);
      }
    }
  }
}

export function getMaterialDatasheet(id, qs, appContent, hash = null, appCatalogId = null) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    dispatch({ type: 'DATASHEET_LOADING' });

    api.materials
      .getMaterialDatasheet(id, qs, appContent, roots.language, roots.unitSystem, hash, appCatalogId)
      .then((roots) => {
        if (roots && roots.data && roots.data.length > 0) {
          for (let element of roots.data) {
            addUUIDs(element);
          }
        }

        let materialName = null;
        let materialId = null;
        if (roots && roots.data) {
          for (let buildingBlock of roots.data) {
            if (buildingBlock.type === 'title') {
              materialName = buildingBlock.data.content;
              materialId = buildingBlock.data.materialId;

              break;
            }
          }
        }

        dispatch({
          type: 'SET_MATERIAL_DATASHEET',
          id: materialId,
          materialName,
          roots,
        });
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getCompareDetails(ids) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();
    if (ids && ids.length > 0) {
      api.materials
        .getCompareDetails(ids, roots.appContent, roots.language, roots.unitSystem)
        .then((roots) => {
          dispatch({
            type: 'SET_COMPARE_DETAILS',
            roots,
          });
        })
        .catch((error) => {
          dispatch(gettingAppsFail());
          console.error(error);
        });
    } else {
      dispatch({
        type: 'SET_COMPARE_DETAILS',
        roots: [],
      });
    }
  };
}

export function getBuildingBlockCompareDetails(ids) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();
    if (ids && ids.length > 0) {
      api.materials
        .getCompareDetails(ids, roots.appContent, roots.language, roots.unitSystem, 'id')
        .then((roots) => {
          dispatch({
            type: 'SET_BB_COMPARE_DETAILS',
            roots,
          });
        })
        .catch((error) => {
          dispatch(gettingAppsFail());
          console.error(error);
        });
    } else {
      dispatch({
        type: 'SET_BB_COMPARE_DETAILS',
        roots: [],
      });
    }
  };
}

export function getLandingBuildingBlocks(view) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(view, roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_LANDING_BB',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getResultSummary(name) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(name, roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_RESULT_SUMMARY',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getLCAGeneric() {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks('genericc02tracker', roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_LCA_GENERIC',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getBBFooter(page) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(page ? page : 'footer', roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_BB_FOOTER',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getBBTopBar(page) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(page ? page : 'topbar', roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_BB_TOPBAR',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getBBWaffleMenu(page) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(page ? page : 'wafflemenu', roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_BB_WAFFLEMENU',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getBBAccountPanel(page) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(page ? page : 'accountpanel', roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_BB_ACCOUNTPANEL',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getBBPageForDialog(name) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(name, roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_BB_PAGE_FOR_DIALOG',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getBBPageForComponent(name) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(name, roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_BB_PAGE_FOR_COMPONENT',
            blocks: roots.blocks,
            page: name,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getAppTitle(name) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getLandingBuildingBlocks(name, roots.appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots.blocks) {
          dispatch({
            type: 'SET_APP_TITLE',
            blocks: roots.blocks,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getPlotJsonMap(entityMap) {
  const api = getApi();

  return (dispatch) => {
    dispatch({
      type: 'SET_PLOT_JSONMAP',
      plotJsonMap: 'LOADING',
    });

    api.materials
      .getPlotJsonMap(entityMap)
      .then((plotJsonMap) => {
        let outputJsonMap = new Map();
        for (const [key, value] of plotJsonMap) {
          let plotEntity = entityMap.get(key).entity;
          let plotJson = value;
          let outputJson = { data: [], layout: {} };
          outputJson.layout.title = plotEntity.attributes.plots[0].name;
          plotEntity.attributes.plots[0].plotAxes.forEach((axis) => {
            let axisName = axis.name;
            if (axis.unit) {
              axisName += ' [' + axis.unit + ']';
            }
            let outputAxis = {
              title: axisName,
              type: axis.scale,
            };
            for (let _key in axis) {
              if (axis[_key] && _key !== 'name' && _key !== 'type' && _key !== 'unit' && _key !== 'scale') {
                outputAxis[_key] = axis[_key];
              }
            }
            switch (axis.type) {
              case 'X':
                outputJson.layout.xaxis = outputAxis;
                break;
              case 'Y':
                outputJson.layout.yaxis = outputAxis;
                break;
              case 'Z':
                outputJson.layout.zaxis = outputAxis;
                break;
              default:
                break;
            }
          });

          plotEntity.attributes.plots[0].curves.curveInfo.forEach((curve) => {
            let outputCurve = {};
            plotJson.forEach((curveData) => {
              if (curveData.name !== curve.name && curveData.name !== curve.legend) {
                return;
              } else {
                // This is the correct curve
                Object.keys(curve.data).forEach((dataRef) => {
                  plotEntity.attributes.plots[0].plotAxes.forEach((axis) => {
                    if (axis.type !== dataRef) {
                      return;
                    } else {
                      // This is the correct axis
                      outputCurve[axis.type.toLowerCase()] = curveData.data[dataRef];
                    }
                  });

                  outputCurve.name = curveData.legend ? curveData.legend : curveData.name;
                  outputCurve.showlegend = true;
                });
              }
            });
            outputJson.data.push(outputCurve);
          });

          outputJsonMap.set(key, outputJson);
        }

        dispatch({
          type: 'SET_PLOT_JSONMAP',
          plotJsonMap: outputJsonMap,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getPlot(plotEntity, id) {
  const api = getApi();
  if (!id) {
    return {
      type: 'SET_PLOT_JSON',
      plotJson: null,
    };
  }
  return (dispatch) => {
    dispatch({
      type: 'SET_PLOT_JSON',
      plotJson: { data: [], layout: {} },
    });

    api.materials
      .getPlot(id)
      .then((plotJson) => {
        let outputJson = { data: [], layout: {} };
        outputJson.layout.title = plotEntity.attributes.plots[0].name;
        plotEntity.attributes.plots[0].plotAxes.forEach((axis) => {
          let axisName = axis.name;
          if (axis.unit) {
            axisName += ' [' + axis.unit + ']';
          }
          let outputAxis = {
            title: axisName,
            type: axis.scale,
          };
          for (let _key in axis) {
            if (axis[_key] && _key !== 'name' && _key !== 'type' && _key !== 'unit' && _key !== 'scale') {
              outputAxis[_key] = axis[_key];
            }
          }
          switch (axis.type) {
            case 'X':
              outputJson.layout.xaxis = outputAxis;
              break;
            case 'Y':
              outputJson.layout.yaxis = outputAxis;
              break;
            case 'Z':
              outputJson.layout.zaxis = outputAxis;
              break;
            default:
          }
        });

        plotEntity.attributes.plots[0].curves.curveInfo.forEach((curve) => {
          let outputCurve = {};
          plotJson.forEach((curveData) => {
            if (curveData.name !== curve.name && curveData.name !== curve.legend) {
              return;
            } else {
              // This is the correct curve
              Object.keys(curve.data).forEach((dataRef) => {
                plotEntity.attributes.plots[0].plotAxes.forEach((axis) => {
                  if (axis.type !== dataRef) {
                    return;
                  } else {
                    // This is the correct axis
                    outputCurve[axis.type.toLowerCase()] = curveData.data[dataRef];
                  }
                });

                outputCurve.name = curveData.legend ? curveData.legend : curveData.name;
                outputCurve.showlegend = true;
              });
            }
          });
          outputJson.data.push(outputCurve);
        });
        dispatch({
          type: 'SET_PLOT_JSON',
          plotJson: outputJson,
        });
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getRanges(qs) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    dispatch({
      type: 'SET_RANGES_LOADING',
    });

    api.materials
      .getRanges(
        qs,
        roots.searchCatalog,
        roots.unitSystem,
        roots.appContent,
        roots.filterData,
        roots.previousFilterData,
        roots.ranges,
        roots.storedTags,
        roots.language
      )
      .then((ranges) => {
        if (ranges) {
          dispatch({
            type: 'REPLACE_RANGES',
            ranges: ranges,
          });
        }
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          dispatch(gettingAppsFail());
          console.error(error);
        }
      });
  };
}

export function getMaterialClassifications(qs) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getFeatureCount(qs, roots.unitSystem, null, roots.appContent, roots.language)
      .then((featureCounts) => {
        api.materials
          .getClassifications(qs, roots.appContent, roots.language, roots.unitSystem)
          .then((roots) => {
            dispatch(replaceAppsClassifications(roots, featureCounts));
          })
          .catch((error) => {
            dispatch(gettingAppsFail());
            console.error(error);
          });
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getContentDefinitions() {
  const api = getApi();
  return (dispatch) => {
    api.materials
      .getContentDefinitions()
      .then((roots) => {
        if (roots && roots.length > 0) {
          let contentDefinitionMap = {};
          roots.forEach((contentDefinition) => {
            contentDefinitionMap[contentDefinition.definition.name] = contentDefinition.definition;
          });
          dispatch({
            type: 'SET_CONTENT_DEFINITIONS',
            contentDefinitions: contentDefinitionMap,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getCatalogs(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getCatalogs(qs, appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots && roots.length > 0) {
          dispatch({
            type: 'SET_CATALOGS',
            catalogs: roots,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function checkAppAccess(appName) {
  const api = getApi();
  if (!appName) return;
  return (dispatch, getState) => {
    const { roots } = getState();
    const key = appName.replace(/\s/g, '');
    let updatePerms = {
      type: 'USER_APP_ACCESS',
      perm: {},
    };
    api.materials
      .checkAppAccess(roots.appContent.content, appName)
      .then((response) => {
        updatePerms.perm = response.success && response.data;
        // check for data and perms
        dispatch(updatePerms);
      })
      .catch((error) => {
        updatePerms[key] = false;
        dispatch(updatePerms);
        console.error(error);
      });
  };
}

export function getUserPreferences() {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    if (roots.appContent) {
      if (roots.appContent.preferences) {
        if (roots.appContent.preferences.length > 0) {
          dispatch({
            type: 'REPLACE_USER_PREFS',
            preferences: roots.appContent.preferences[0],
          });
        } else {
          dispatch({
            type: 'REPLACE_USER_PREFS',
            preferences: { tableProps: [] },
          });
        }
      } else {
        api.materials
          .getUserPreferences(roots.appContent.content)
          .then((roots) => {
            if (roots && roots.data && roots.data.preferences && roots.data.preferences.length > 0) {
              dispatch({
                type: 'REPLACE_USER_PREFS',
                preferences: roots.data.preferences[0],
              });
            } else {
              dispatch({
                type: 'REPLACE_USER_PREFS',
                preferences: { tableProps: [] },
              });
            }
          })
          .catch((error) => {
            dispatch(gettingAppsFail());
            console.error(error);
          });
      }
    }
  };
}

export function saveUserPreferences(preferences, reload = true) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    if (roots.appContent && roots.appContent.user && roots.appContent.user.companyId > 0) {
      api.materials
        .saveUserPreferences(preferences, roots.appContent.content)
        .then(() => {
          if (reload) {
            roots.appContent.preferences[0] = preferences;
            dispatch({
              type: 'USER_PREFS_CHANGED',
              preferences: preferences,
            });
          }
        })
        .catch((error) => {
          dispatch(gettingAppsFail());
          console.error(error);
        });
    } else {
      if (reload) {
        roots.appContent.preferences[0] = preferences;

        dispatch({
          type: 'USER_PREFS_CHANGED',
          preferences: preferences,
          reload,
        });
      }
    }
  };
}

export function getSearchCatalog(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getSearchCatalog(qs, appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots && roots.length > 0) {
          dispatch({
            type: 'SET_SEARCH_CATALOG',
            searchCatalog: roots,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getUnits(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getUnits(qs, appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots && roots.length > 0) {
          dispatch({
            type: 'SET_UNITS',
            units: roots,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getNotifications() {
  const api = getApi();
  return (dispatch) => {
    api.materials
      .getNotifications()
      .then((roots) => {
        dispatch({
          type: 'SET_NOTIFICATIONS',
          notifications: roots,
        });
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function dismissNotification(id) {
  const api = getApi();
  return (dispatch) => {
    api.materials.dismissNotification(id).then(dispatch(getNotifications()));
  };
}

export function notifySearchCriteriaUpdated() {
  return {
    type: 'SEARCH_CRITERIA_UPDATED',
  };
}

export function getCompareCatalog(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();
    api.materials
      .getCompareCatalog(qs, appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots && roots.length > 0) {
          dispatch({
            type: 'SET_COMPARE_CATALOG',
            compareCatalog: roots,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getTableColumns(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getTableColumns(qs, appContent, roots.language, roots.unitSystem)
      .then((roots) => {
        if (roots && roots.length > 0) {
          dispatch({
            type: 'SET_TABLE_COLUMNS',
            tableColumns: roots,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getScatterData(x, y, qs) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    dispatch({
      type: 'SET_SCATTER_DATA',
      scatterData: [],
    });

    api.materials
      .getScatterData(roots.appContent, roots.language, roots.unitSystem, x, y, roots.filterData, qs)
      .then((roots) => {
        if (roots) {
          dispatch({
            type: 'SET_SCATTER_DATA',
            scatterData: roots,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getAvailLanguages(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();

    api.materials
      .getAvailLanguages(qs, appContent, roots.language)
      .then((roots) => {
        if (roots) {
          dispatch({
            type: 'SET_AVAIL_LANGUAGES',
            availLanguages: roots,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getDerivedContentDefinitions(contentType) {
  function getCommonContentDefinition(contentDefinition) {
    const derivedCdefs = contentDefinition[0].derivedCdefs;
    for (const item of derivedCdefs) {
      if (item.cdef.definition.name === 'CommonSourceDirectory') {
        return item;
      }
    }

    return undefined;
  }

  function getUnCommonContentDefinition(contentDefinition) {
    let unCommonContentDefinitions = [];
    const derivedCdefs = contentDefinition[0].derivedCdefs;
    for (const item of derivedCdefs) {
      if (item.cdef.definition.name !== 'CommonSourceDirectory') {
        unCommonContentDefinitions.push(item);
      }
    }

    return unCommonContentDefinitions;
  }
  const api = getApi();
  return (dispatch) => {
    api.materials
      .getDerivedContentDefinitions(contentType)
      .then((roots) => {
        if (roots && roots.length > 0) {
          let derivedContentDefinitions = [];

          const unCommonContentDefinitions = getUnCommonContentDefinition(roots);
          const commonContentDefinitions = getCommonContentDefinition(roots);

          derivedContentDefinitions.push(...unCommonContentDefinitions);
          if (commonContentDefinitions) {
            derivedContentDefinitions.push(commonContentDefinitions);
          }

          dispatch({
            type: 'SET_DERIVED_CONTENT_DEFINITIONS',
            derivedContentDefinitions: derivedContentDefinitions,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function toggleImportModal(isImportModalOpened) {
  return {
    type: 'MATERIAL_IMPORT_MODAL_OPEN',
    isImportModalOpened,
  };
}

export function toggleExportModal(isExportModalOpened) {
  return {
    type: 'MATERIAL_EXPORT_MODAL_OPEN',
    isExportModalOpened,
  };
}

export function clearBBPageForDialog() {
  return {
    type: 'CLEAR_BB_PAGE_FOR_DIALOG',
  };
}

export function importMaterials(file, importProvider, importSourceTemplate) {
  const api = getApi();

  return (dispatch, getState) => {
    const { loggedInUser } = getState();
    const userId = get(loggedInUser, 'id');

    api.materials
      .importMaterialsFromFile(file, importProvider, importSourceTemplate)
      .then(() => {
        //dispatch(replaceApps(roots));
        //dispatch(addNotificationSuccessEntity({message: "Import successful"}));
        createNotification({
          type: 'IMPORT_COMPLETE',
          userId,
          variables: {},
        });
        dispatch(clearApps());
      })
      .catch((error) => {
        createNotification({
          type: 'IMPORT_FAILED',
          userId,
          variables: {},
        });
        console.error(error);
      });
  };
}

export function qtExportMaterials(materialIds, applicationType, applicationName, qtHandler) {
  const api = getApi();
  return (dispatch) => {
    dispatch(qtExportingMaterials());
    api.materials.qtExportMaterials(materialIds, applicationType, applicationName, qtHandler).catch((error) => {
      dispatch(qtExportingMaterialsFail());
      console.error(error);
    });
  };
}

export function setEntityView(entityView) {
  return {
    type: 'MATERIAL_ENTITY_VIEW',
    entityView,
  };
}

/*export function setTheme(theme) {
  return {
    type: 'MATERIAL_THEME',
    theme,
  };
}*/

export function setFavoriteList(favoriteList) {
  return {
    type: 'SET_FAVORITE_LIST',
    favoriteList,
  };
}

export function setFavoriteView(favoriteView) {
  return {
    type: 'SET_FAVORITE_VIEW',
    favoriteView,
  };
}

export function setSimilarId(id) {
  return {
    type: 'SET_SIMILAR_ID',
    id,
  };
}

export function setSimilarProfile(profile) {
  return {
    type: 'SET_SIMILAR_PROFILE',
    profile,
  };
}

export function getPolarData(selectionData) {
  const api = getApi();

  return (dispatch, getState) => {
    dispatch({
      type: 'SET_POLAR_DATA_LOADING',
    });
    var _getState = getState(),
      roots = _getState.roots;

    api.materials
      .getPolarData(selectionData, roots.language, roots.unitSystem, roots.appContent)
      .then((data) => {
        dispatch({
          type: 'SET_POLAR_DATA',
          polarData: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'GET_POLAR_DATA_FAIL',
          error: error,
        });
      });
  };
}

export function getPolarCatalog(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();
    api.materials
      .getPolarCatalog(qs, appContent, roots.language, roots.unitSystem)
      .then((res) => {
        if (res && res.length > 0) {
          dispatch({
            type: 'SET_POLAR_CATALOG',
            polarCatalog: res,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getScatterCatalog(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();
    api.materials
      .getScatterCatalog(qs, appContent, roots.language, roots.unitSystem)
      .then((res) => {
        if (res && res.length > 0) {
          dispatch({
            type: 'SET_SCATTER_CATALOG',
            scatterCatalog: res,
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function getImpactAssessmentData(qs, appContent) {
  const api = getApi();
  return (dispatch, getState) => {
    const { roots } = getState();
    api.materials
      .getImpactAssessmentData(qs, appContent, roots.language, roots.unitSystem)
      .then((res) => {
        if (res && res.data) {
          let compoundMap = new Map();
          let countryMap = new Map();
          let machineMap = new Map();

          let compoundProps = [];
          let machineProps = [];
          let countryProps = [];

          for (let data of res.data) {
            if (data.usemask === null) {
              let typeMap = null;
              if (compoundMap.has(data.name)) {
                typeMap = compoundMap.get(data.name);
              } else {
                typeMap = new Map();
                compoundMap.set(data.name, typeMap);
              }

              for (let polymer of data.data) {
                if (typeMap.has(polymer.type)) {
                  typeMap.set(polymer.type + '|' + polymer.name, polymer);
                } else {
                  typeMap.set(polymer.type, polymer);
                }
              }

              compoundProps = data.props;
            } else if (data.usemask === 38) {
              for (let country of data.data) {
                countryMap.set(country.id, country);
              }

              countryProps = { name: data.name, props: data.props };
            } else if (data.usemask === 37) {
              let typeMap = null;
              if (machineMap.has(data.name)) {
                typeMap = machineMap.get(data.name);
              } else {
                typeMap = new Map();
                machineMap.set(data.name, typeMap);
              }

              for (let machine of data.data) {
                if (typeMap.has(machine.id)) {
                  typeMap.set(machine.id + '|' + machine.name, machine);
                } else {
                  typeMap.set(machine.id, machine);
                }
              }

              machineProps = { name: data.name, props: data.props };
            }
          }
          dispatch({
            type: 'SET_IMPACT_ASSESSMENT_DATA',
            impactAssessmentData: { compoundMap, countryMap, machineMap, compoundProps, machineProps, countryProps },
          });
        }
      })
      .catch((error) => {
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function exportCompareExcel(appContent, mode, matKeys) {
  const api = getApi();
  return (dispatch, getState) => {
    dispatch({
      type: 'EXPORT_COMPARE',
      exportCompare: {
        isLoading: true,
        message: 'Download Started',
      },
    });
    dispatch(showLocalNotification('Download Started', 'info', 1000));
    const { roots } = getState();
    api.materials
      .exportCompareExcel(appContent, mode, roots.language, roots.unitSystem, matKeys)
      .then((res) => {
        dispatch({
          type: 'EXPORT_COMPARE',
          exportCompare: {
            isLoading: false,
            message: (res.data && res.data.message) || 'Download Completed',
          },
        });
        let errorMessage = res.data && res.data.message;
        dispatch(showLocalNotification(errorMessage || 'Download Completed', errorMessage ? 'error' : 'success', 3000));
      })
      .catch((error) => {
        dispatch({
          type: 'EXPORT_COMPARE',
          exportCompare: {
            isLoading: false,
            message: error.message,
          },
        });
        dispatch(showLocalNotification(error.message, 'error', 3000));
        dispatch(gettingAppsFail());
        console.error(error);
      });
  };
}

export function setHasSearchCriteria() {
  return {
    type: 'SET_HAS_SEARCH_CRITERIA',
  };
}

export function setPluginSource(source) {
  return {
    type: 'SET_PLUGIN_SOURCE',
    source,
  };
}
